// extracted by mini-css-extract-plugin
export var preview_block = "PreviewBlock2-module--preview_block--nbVCa";
export var video = "PreviewBlock2-module--video--miF6L";
export var image_mobile = "PreviewBlock2-module--image_mobile--3ZxEi";
export var hidden_mobile = "PreviewBlock2-module--hidden_mobile--zBdGG";
export var content = "PreviewBlock2-module--content--BMOns";
export var content_right = "PreviewBlock2-module--content_right--qvxhF";
export var content_center = "PreviewBlock2-module--content_center--MtRkZ";
export var content_left = "PreviewBlock2-module--content_left--zM37n";
export var shadow = "PreviewBlock2-module--shadow--uS9t6";
export var button = "PreviewBlock2-module--button--iXNYU";
export var button_left = "PreviewBlock2-module--button_left--98xLt";
export var button_center = "PreviewBlock2-module--button_center--HPqJZ";
export var button_right = "PreviewBlock2-module--button_right--ctY0+";