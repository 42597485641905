import React from 'react'

const AppStoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.582" cy="13.3364" r="13" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7931 6.80253C11.9664 6.70105 12.1588 6.65989 12.3453 6.67182C12.656 6.6917 12.9516 6.86035 13.121 7.14921L13.4497 7.70997L13.7784 7.14921C14.049 6.68703 14.6428 6.53192 15.1055 6.80253C15.5676 7.07315 15.7228 7.6674 15.4521 8.12958L11.7296 14.4835H13.7557C14.0588 14.6853 14.3191 14.9523 14.5068 15.2792L14.5087 15.2811C14.7221 15.6521 14.7686 16.0534 14.6925 16.4234H7.218C6.68258 16.4234 6.24805 15.9889 6.24805 15.4535C6.24805 14.918 6.68258 14.4835 7.218 14.4835H9.4809L12.3254 9.62903L11.4473 8.13053C11.1762 7.66835 11.3309 7.07363 11.7931 6.80253ZM14.7275 11.2412L15.1282 10.5753L17.4186 14.4835H19.8273C20.3628 14.4835 20.7973 14.918 20.7973 15.4535C20.7973 15.9889 20.3628 16.4234 19.8273 16.4234H18.5552L19.402 17.8689C19.6731 18.331 19.5185 18.9253 19.0563 19.1959C18.9021 19.2861 18.7329 19.3285 18.5666 19.3285C18.2334 19.3285 17.9097 19.1577 17.7293 18.8492L14.6754 13.6376C14.271 12.8864 14.2852 11.9764 14.7275 11.2412ZM7.70865 17.508C7.95842 17.4294 8.22092 17.3875 8.49106 17.3953H8.4939C8.99003 17.4098 9.40463 17.6342 9.68834 17.9655L9.16642 18.8559C8.98553 19.1638 8.66131 19.3361 8.32813 19.3361C8.16179 19.3361 7.99359 19.2932 7.83937 19.2025C7.37719 18.9319 7.22159 18.3372 7.49269 17.8755L7.70865 17.508Z"
      fill="#0695D7"
    />
  </svg>
)

export default AppStoreIcon
