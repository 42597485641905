import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'
import AppStoreIcon from '../../svg/app-store-icon'
import PlayMarketIcon from '../../svg/play-market-icon'
import Button from '../button/button'
import { useTranslations } from '../../hooks/use-translations'
import * as s from './styles.module.scss'

interface PreviewBlockProps {
  variant?: 'inspection' | 'dashboard' | 'value'
  btnPosition?: 'left' | 'center' | 'right'
  btnText?: string
  imgSource: IGatsbyImageData
  imgAlt: string
  imgTitle: string
  appLink?: string
  hasShadow?: boolean
  hasBtnIcon?: boolean
  className?: string
  onClick?: () => void
}

export const PreviewBlock: React.FC<PreviewBlockProps> = ({
  imgSource,
  imgAlt,
  imgTitle,
  appLink,
  variant = 'value',
  btnPosition = 'center',
  btnText,
  hasShadow = false,
  hasBtnIcon,
  className,
  onClick,
  children,
}) => {
  const { locale, t } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  const handleClickBtn = () => {
    if (onClick) return onClick()
    window.open(appLink, '_blank')
  }

  return variant === 'dashboard' ? (
    <section className="px-5">
      <div className="container">
        <div className={s.preview_block_dash}>
          <div>
            <GatsbyImage
              image={
                images[`dash_mockup_${locale}`].childImageSharp.gatsbyImageData
              }
              alt={t('_dash_image_alt')}
              title={t('_dash_image_title')}
            />
          </div>
          <div className="flex flex-col items-end justify-around mb-4">
            <GatsbyImage image={imgSource} alt={imgAlt} title={imgTitle} />
            <div>
              <Button
                className={s.preview_block_dash__btn}
                onClick={handleClickBtn}
              >
                <AppStoreIcon />
                <PlayMarketIcon />
                <span>{btnText}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section className={cn('px-5', className)}>
      <div className={cn('container', variant === 'inspection' && 'pt-8')}>
        <GatsbyImage
          image={imgSource}
          alt={t(imgAlt)}
          title={t(imgTitle)}
          className={cn(hasShadow && s.preview_block__shadow)}
        />
        {children}
        {btnText && (
          <Button
            className={cn(
              s.preview_block__btn,
              s[`preview_block__btn_${btnPosition}`]
            )}
            onClick={handleClickBtn}
          >
            {hasBtnIcon && (
              <>
                <AppStoreIcon />
                <PlayMarketIcon />
              </>
            )}
            <span>{btnText}</span>
          </Button>
        )}
      </div>
    </section>
  )
}

const imagesQuery = graphql`
  query {
    dash_mockup_ru: file(relativePath: { eq: "dashboard/image-phone.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 265
          height: 529
          placeholder: BLURRED
        )
      }
    }
    dash_mockup_en: file(relativePath: { eq: "dashboard/image-phone-en.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 265
          height: 529
          placeholder: BLURRED
        )
      }
    }
    dash_mockup_us: file(relativePath: { eq: "dashboard/image-phone-en.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 265
          height: 529
          placeholder: BLURRED
        )
      }
    }
  }
`
