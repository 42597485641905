import React from 'react'
import cn from 'classnames'

interface ModuleSectionProps {
  title?: string
  className?: string
  titleClassName?: string
}

export const ModuleSection: React.FC<ModuleSectionProps> = ({
  title,
  className,
  titleClassName,
  children,
}) => (
  <section className={cn('px-5 pb-16 pt-20', className)}>
    <div className="container">
      {title && (
        <h2
          className={cn(
            'title is-2 has-text-primary whitespace-pre-line is-uppercase',
            titleClassName
          )}
        >
          {title}
        </h2>
      )}
      {children}
    </div>
  </section>
)
