import React from 'react'
import cn from 'classnames'

import * as s from './index.module.sass'

interface MockupImageProps {
  type: 'mockup1' | 'mockup2'
  className?: string
}

const MockupImage: React.FC<MockupImageProps> = ({
  type,
  className,
  children,
}) => {
  return <div className={cn(className, s[type])}>{children}</div>
}

export default MockupImage
