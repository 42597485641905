import React from 'react'

const PlayMarketIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.582" cy="13.3364" r="13" fill="white" />
    <path
      d="M9.35841 5.99868C9.3165 5.99427 9.27899 6.01413 9.23487 6.01633C9.19737 6.01854 9.16649 6.00971 9.12898 6.01633C9.12237 6.01633 9.11795 6.01633 9.11134 6.01633C9.09369 6.01633 9.07604 6.01633 9.05839 6.01633C8.99662 6.02957 8.93706 6.05383 8.88191 6.08692C8.82235 6.11781 8.7672 6.15972 8.72308 6.21046C8.50248 6.39577 8.37012 6.68255 8.37012 6.95168V19.8171C8.37012 20.0951 8.49365 20.4304 8.79367 20.5936C8.81573 20.6047 8.8422 20.6025 8.86426 20.6113C8.88191 20.6179 8.89956 20.6245 8.91721 20.6289C9.18634 20.7194 9.4665 20.6664 9.67608 20.5407C9.68269 20.5407 9.68711 20.5407 9.69372 20.5407C9.93639 20.3929 15.0058 17.2935 15.0058 17.2935L17.706 15.6698C17.706 15.6698 19.9958 14.2845 20.3002 14.0992C20.5583 13.9425 20.7988 13.6447 20.7944 13.2873C20.79 12.93 20.5429 12.6652 20.3002 12.5285C20.223 12.4844 19.5436 12.0762 18.9237 11.699C18.3303 11.3372 17.8096 11.024 17.7589 10.9931C17.7413 10.9799 17.7236 10.9688 17.706 10.9578L15.0058 9.31652C15.0058 9.31652 10.093 6.3362 9.79961 6.15752C9.66946 6.08031 9.51504 6.01633 9.35841 5.99868ZM9.49959 7.74584L14.8293 13.305L9.49959 18.8641V7.74584ZM12.2174 8.94591C13.2145 9.55036 14.4234 10.2872 14.4234 10.2872L16.5059 11.5578L15.6058 12.4932L12.2174 8.94591ZM17.4942 12.1579C17.7104 12.2902 17.9089 12.4071 18.3413 12.6697C18.8134 12.9564 19.1421 13.1395 19.4178 13.305C18.8994 13.6182 17.9155 14.2249 17.5118 14.4698L16.4 13.305L17.4942 12.1579ZM15.6058 14.1345L16.5059 15.0698L14.4234 16.3405C14.4234 16.3405 13.1858 17.0839 12.1998 17.6817L15.6058 14.1345Z"
      fill="#0695D7"
    />
  </svg>
)

export default PlayMarketIcon
