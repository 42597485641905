import React, { HTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'
import * as s from './list.module.sass'

interface IProps extends HTMLAttributes<HTMLUListElement> {
  items: ReactNode[]
  itemClassName?: string
}

export const List: React.FC<IProps> = ({ items, itemClassName, className }) => {
  const renderItem = (itm: ReactNode) => (
    <li className={cn(s.item, itemClassName)}>{itm}</li>
  )
  return <ul className={cn(s.wrap, className)}>{items?.map(renderItem)}</ul>
}
