import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'

import Container from '../../Common/Container'
import { Button } from '../../Common/Button'

import * as s from './PreviewBlock2.module.scss'

interface PreviewBlock2Props {
  src: IGatsbyImageData | string
  srcTitle: string
  srcPosition?: 'left' | 'center' | 'right'
  mobileImageSrc?: IGatsbyImageData
  className?: string
  srcClassName?: string
  hasShadow?: boolean
  btnData?: {
    position: 'left' | 'center' | 'right'
    text: string
    onClick: () => void
  }
}

export const PreviewBlock2: React.FC<PreviewBlock2Props> = ({
  src,
  srcTitle,
  srcPosition = 'center',
  mobileImageSrc,
  hasShadow = false,
  btnData,
  className,
  srcClassName,
  children,
}) => {
  return (
    <div className={cn(s.preview_block, className)}>
      <Container>
        <div className={cn(s.content, s[`content_${srcPosition}`])}>
          {typeof src === 'string' ? (
            <video
              autoPlay
              muted
              playsInline
              title={srcTitle}
              className={cn(s.video, srcClassName, hasShadow && s.shadow)}
            >
              <source src={src} type="video/mp4" />
            </video>
          ) : (
            <>
              <GatsbyImage
                image={src}
                alt={srcTitle}
                title={srcTitle}
                className={cn(
                  srcClassName,
                  hasShadow && s.shadow,
                  mobileImageSrc && s.hidden_mobile
                )}
              />
              {mobileImageSrc && (
                <GatsbyImage
                  image={mobileImageSrc}
                  alt={srcTitle}
                  title={srcTitle}
                  className={cn(
                    s.image_mobile,
                    srcClassName,
                    hasShadow && s.shadow
                  )}
                />
              )}
            </>
          )}
        </div>
        {children}
        {btnData && (
          <Button
            size="large"
            onClick={btnData.onClick}
            className={cn(s.button, s[`button_${btnData.position}`])}
          >
            {btnData.text}
          </Button>
        )}
      </Container>
    </div>
  )
}
