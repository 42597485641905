import React from 'react'
import cn from 'classnames'
import SignalMiddleIcon from '../../svg/signal-middle-icon'
import * as s from './description-block.module.scss'

interface DescriptionBlockProps {
  title: string
  btnCaption?: string
  className?: string
}

export const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  title,
  btnCaption,
  className,
  children,
}) => (
  <section className={cn(s.description_block_section, className)}>
    <div className="container pb-5">
      <h1
        className={
          'title is-2 has-text-primary whitespace-pre-line is-uppercase'
        }
      >
        {title}
      </h1>
      <div className={cn('flex items-center', s.description_block__wrap)}>
        {btnCaption && (
          <div className={cn('has-text-white', s.description_block__wrap_btn)}>
            <SignalMiddleIcon className="has-text-white" />
            <span
              className={cn('is-uppercase', s.description_block__wrap_btn_text)}
            >
              {btnCaption}
            </span>
          </div>
        )}
        {children}
      </div>
    </div>
  </section>
)
