import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import Button from '../components/button/button'
import { DescriptionBlock } from '../components/description-block'
import { PreviewBlock } from '../components/preview-block'
import MockupImage from '../components/Common/MockupImage'
import { List } from '../components/list/list'
import { ModuleSection, ModuleSectionItem } from '../components/module-section'

import { valueLink } from '../data'
import { toLink } from '../utils'
import { useTranslations } from '../hooks/use-translations'
import { DemoBlock } from '../components/demo-block'
import { LocalesEnum } from '../types/locales.enum'

import valueGif from '../assets/images/gif/value.gif'
import * as s from '../pages/pages.module.scss'

const MODULE_NAME = 'VALUE'
const template = new Array(6).fill(0)
const templateUs = new Array(4).fill(0)

const ValueModulePage: React.FC = () => {
  const { locale, t } = useTranslations()
  const moduleRef = React.useRef(null)
  const [visible, setVisible] = React.useState(false)

  const handleFieldView = (intersection: IntersectionObserverEntryInit[]) => {
    const [IntersectionEntry] = intersection
    if (IntersectionEntry.intersectionRatio) {
      setVisible(true)
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleFieldView, {
      threshold: 0,
    })
    if (moduleRef.current) {
      observer.observe(moduleRef.current)
    }

    return () => {
      if (moduleRef.current) {
        observer.unobserve(moduleRef.current)
      }
    }
  }, [])

  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO title={MODULE_NAME} description={t('_value__lead0')} />
      <DescriptionBlock title={t('Description value')} btnCaption="Value" />

      <PreviewBlock
        imgSource={images.preview.childImageSharp.gatsbyImageData}
        imgAlt={t('_value__image_title')}
        imgTitle={t('_value__image_title')}
      >
        <div className={s.value__module_wrap}>
          <div className={`is-size-5 ${s.value__module}`}>
            <span
              ref={moduleRef}
              className={`${s.value__module_strip} ${visible && s.active}`}
            ></span>
            <List
              items={(LocalesEnum.Us === locale ? templateUs : template).map(
                (_, idx) => t(`_value_text${idx + 1}`)
              )}
              className={s.value__module_list}
            />
          </div>
          <Button isFullWidth onClick={() => toLink(valueLink)}>
            {t('Connect')}
          </Button>
        </div>
      </PreviewBlock>
      <ModuleSection title={t('Module features')}>
        <ModuleSectionItem
          subtitle={t('_value__subtitle')}
          lead={t('_value__lead0')}
          author="Antony S."
        >
          <GatsbyImage
            image={images.image1.childImageSharp.gatsbyImageData}
            alt={`${t('_value__image_title')} 1`}
            title={`${t('_value__image_title')} 1`}
            objectFit="contain"
          />
        </ModuleSectionItem>
        <ModuleSectionItem
          subtitle={t('_value_subtitle1')}
          lead={t('_value__lead1')}
          author="Alexander K."
          reverseElements
        >
          <GatsbyImage
            image={images.image2.childImageSharp.gatsbyImageData}
            alt={`${t('_value__image_title')} 2`}
            title={`${t('_value__image_title')} 2`}
            objectFit="contain"
          />
        </ModuleSectionItem>
        <ModuleSectionItem
          subtitle={t('_value_subtitle2')}
          lead={t('_value__lead2')}
          author="Andrew S."
        >
          <MockupImage type="mockup2">
            <GatsbyImage
              image={images.image3.childImageSharp.gatsbyImageData}
              alt={`${t('_value__image_title')} 3`}
              title={`${t('_value__image_title')} 3`}
              style={{ position: 'absolute' }}
            />
          </MockupImage>
        </ModuleSectionItem>
        <ModuleSectionItem
          subtitle={t('_value_subtitle3')}
          lead={t('_value__lead3')}
          author="Antony S."
          reverseElements
        >
          <MockupImage type="mockup1">
            <img
              src={valueGif}
              alt={`${t('_value__image_title')} 4`}
              title={`${t('_value__image_title')} 4`}
            />
          </MockupImage>
        </ModuleSectionItem>
      </ModuleSection>

      <DemoBlock
        moduleName={t('Value demo')}
        title={t('Request a demo_1')}
        app={{
          name: t('Connect Value'),
          link: valueLink,
          btnText: t('Connect'),
        }}
        demoClass={s.value__demoClass}
      />
    </>
  )
}

export default ValueModulePage

const imagesQuery = graphql`
  query {
    preview: file(relativePath: { eq: "value/image-preview.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image1: file(relativePath: { eq: "value/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 404, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "value/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 568, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "value/image-3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
  }
`
