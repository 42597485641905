import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import Button from '../button/button'
import ContactForm, { RequestFormType } from '../contact-form'

import { useTranslations } from '../../hooks/use-translations'
import AppStoreIcon from '../../svg/app-store-icon'
import PlayMarketIcon from '../../svg/play-market-icon'
import * as s from './styles.module.scss'

interface DemoBlockProps {
  title?: string
  moduleName?: string
  moduleBtnText?: string
  demoClass?: string
  requestFormType?: RequestFormType
  formTitle?: string
  containerClass?: string
  app?: {
    name: string
    link: string
    btnText: string
    hasBtnIcon?: boolean
  }
}

export const DemoBlock: React.FC<DemoBlockProps> = ({
  title,
  moduleName,
  moduleBtnText,
  app,
  requestFormType = 'Demo',
  formTitle,
  demoClass,
  containerClass,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <section className={cn('w-full px-5 pt-6', demoClass && demoClass)}>
        <div className={cn('container mb-16', containerClass)}>
          {title && (
            <h2 className="title is-2 has-text-primary whitespace-pre-line is-uppercase mb-8">
              {title}
            </h2>
          )}
          <div
            className={cn('columns is-multiline items-end', s.demo_block__item)}
          >
            <div className="column is-12-touch is-offset-0-touch is-6-desktop">
              {moduleName && (
                <p
                  className={cn(
                    'is-size-6 whitespace-pre-line',
                    s.demo_block__item_title
                  )}
                >
                  {moduleName}
                </p>
              )}
              <Button
                size="medium"
                className={cn(s.demo_block__item_btn, s.demo_block__fullwidth)}
                onClick={() => setIsOpenModal(true)}
              >
                <span>{moduleBtnText || t('Request a demo')}</span>
              </Button>
            </div>
            {app && (
              <div className="column is-12-touch is-offset-0-touch is-6-desktop">
                <p
                  className={cn(
                    'is-size-6 whitespace-pre-line',
                    s.demo_block__item_title
                  )}
                >
                  {app.name}
                </p>
                <Button
                  size="medium"
                  className={cn(
                    s.demo_block__item_btn,
                    s.demo_block__fullwidth
                  )}
                  onClick={() => {
                    window.open(app.link, '_blank')
                  }}
                >
                  {app.hasBtnIcon && (
                    <>
                      <AppStoreIcon />
                      <PlayMarketIcon />
                    </>
                  )}
                  <span>{app.btnText}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className={s.demo_block__trapWrapper}>
        <GatsbyImage
          image={images.trap.childImageSharp.gatsbyImageData}
          className={s.demo_block__trap}
          objectFit="fill"
          alt={t('ray')}
          title={t('ray')}
        />
      </div>

      {isOpenModal && (
        <ContactForm
          onClose={() => setIsOpenModal(false)}
          title={formTitle || t('Application demo request')}
          requestType={requestFormType}
        />
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    trap: file(relativePath: { eq: "trap.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
