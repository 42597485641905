import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import { useTranslations } from '../../../hooks/use-translations'
import * as s from '../module-selection.module.scss'

interface ModuleSectionItemProps {
  title?: string
  subtitle: string
  lead?: React.ReactNode
  className?: string
  author?: Author
  reverseElements?: boolean
  withoutAnyTitles?: boolean
  listsText?: string[]
}

const authors = [
  'Dmitri D.',
  'Alexander P.',
  'Roman S.',
  'Andrew S.',
  'Michel K.',
  'Antony S.',
  'Alexander K.',
  'Alexander E.',
] as const

type Author = typeof authors[number]

export const ModuleSectionItem: React.FC<ModuleSectionItemProps> = ({
  title,
  subtitle,
  lead,
  className,
  reverseElements,
  author,
  listsText,
  children,
}) => {
  const sectionRef = React.useRef(null)
  const [visible, setVisible] = React.useState(false)
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  const handleFieldView = (intersection: IntersectionObserverEntryInit[]) => {
    const [IntersectionEntry] = intersection
    if (IntersectionEntry.intersectionRatio) {
      setVisible(true)
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleFieldView, {
      threshold: 0,
    })
    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])

  return (
    <div
      className={cn(
        'columns justify-between is-multiline',
        className,
        reverseElements && 'flex-row-reverse'
      )}
    >
      <div
        className={cn(
          'flex column  is-12-touch',
          author && 'pl-10 pt-8',
          children && 'is-6-desktop'
        )}
      >
        {author && (
          <div className={s.module_section__item_description_author}>
            <GatsbyImage
              image={
                images[`author${authors.indexOf(author) + 1}`].childImageSharp
                  .gatsbyImageData
              }
              alt={t(author)}
              title={t(author)}
            />

            <p className={s.module_section__item_description_author_name}>
              {t(author)}
            </p>
          </div>
        )}
        <div className={s.module_section__item_title}>
          {title && (
            <h2 className="title is-2 has-text-primary whitespace-pre-line is-uppercase">
              {title}
            </h2>
          )}
          <p className="is-size-7 whitespace-pre-line">{subtitle}</p>
          <hr
            ref={sectionRef}
            className={cn(s.module_section__item_strip, visible && s.active)}
          />
          {lead && (
            <p className="is-size-7 has-text-primary whitespace-pre-line">
              {lead}
            </p>
          )}
          {listsText && (
            <ul
              className={cn(
                'is-size-7 has-text-primary',
                s.module_section__lists
              )}
            >
              {listsText.map((text, idx) => (
                <li key={`${text}_${idx}`}>{text}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {children && (
        <div
          className={cn(
            'column is-12-touch is-6-desktop is-offset-0-touch',
            s.module_section__item_preview,
            reverseElements ? 'pr-0' : 'pl-0'
          )}
        >
          {children}
        </div>
      )}
    </div>
  )
}

const imagesQuery = graphql`
  query {
    author1: file(relativePath: { eq: "author/Dmitri_D.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author2: file(relativePath: { eq: "author/Alexander_P.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author3: file(relativePath: { eq: "author/Roman_S.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author4: file(relativePath: { eq: "author/Andrew_S.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author5: file(relativePath: { eq: "author/Michel_K.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author6: file(relativePath: { eq: "author/Antony_S.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author7: file(relativePath: { eq: "author/Alexander_K.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
    author8: file(relativePath: { eq: "author/Alexander_E.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
      }
    }
  }
`
