import React from 'react'
import cn from 'classnames'
import Container from '../../Common/Container'

import * as s from './DescriptionBlock2.module.scss'

interface DescriptionBlock2Props {
  title: string
  description?: string
  classNameTitle?: string
  highlightTitleWordsByIdx?: number[]
}

export const DescriptionBlock2: React.FC<DescriptionBlock2Props> = ({
  title,
  classNameTitle,
  description,
  highlightTitleWordsByIdx,
  children,
}) => {
  return (
    <div>
      <Container>
        {renderTitle(title, highlightTitleWordsByIdx, classNameTitle)}
        {(description || children) && (
          <div className={s.content}>
            {description && <p className={s.description}>{description}</p>}
            {children}
          </div>
        )}
      </Container>
    </div>
  )
}

const renderTitle = (
  title: string,
  highlightTitleWordsByIdx?: number[],
  className?: string
) => {
  if (highlightTitleWordsByIdx?.length) {
    return (
      <h2 className={cn(s.title, className)}>
        {title.split(' ').map((t, idx) => (
          <React.Fragment key={t + idx}>
            {highlightTitleWordsByIdx.includes(idx) ? (
              <span style={{ color: '#0695D7' }}>{`${t} `}</span>
            ) : (
              `${t} `
            )}
          </React.Fragment>
        ))}
      </h2>
    )
  }

  return <h2 className={cn(s.title, className)}>{title}</h2>
}
